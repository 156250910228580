<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
                <h2 class="brand-text text-primary ml-1">
                    {{ appName }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" :alt="$t(`pages.${pagename}.pagetitle`)" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t(`pages.${pagename}.welecomtopage`, { appName: appName }) }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t(`pages.${pagename}.msg1`) }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation" #default="{ invalid }">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <b-form-group
                                :label="$t('common.accountoremail')"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false : null"
                                        name="login-email"
                                        placeholder="abc@example.com"
                                        autocomplete="off"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">{{
                                        $t('common.password')
                                    }}</label>
                                    <b-link :to="{ name: 'ForgotPassword', params: {} }">
                                        <small>{{ $t(`pages.${pagename}.msg5`) }}</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false : null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    {{ $t(`pages.${pagename}.msg2`) }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="invalid"
                                @click="validationForm"
                            >
                                {{ $t('common.signin') }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span>{{ $t(`pages.${pagename}.msg3`) }} </span>
                        <b-link :to="{ name: 'Register', params: {} }">
                            <span>{{ $t(`pages.${pagename}.msg4`) }}</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

                    <!-- social buttons -->
                    <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { accountAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { Tientech_getPageRandomNumber } from '@/libs/tientech/api'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'login',
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      random_number: 0,
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    try {
      // let vuethis = this
      this.initialize()
    } catch (error) {
      console.log(error)
    }
  },
  created() {
    this.random_number = Tientech_getPageRandomNumber()
  },
  methods: {
    initialize() {
      try {
        // initialize
      } catch (error) {
        console.log(error)
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (!success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('error_common.input_error'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
        }
        const account = this.userEmail
        const { password } = this

        const obj = {
          account,
          password,
        }
        if (this.$socket.connected === false) {
          this.$swal({
            icon: 'error',
            title: this.$t('error_common.networkisdisconnect'),
            showConfirmButton: true,
            timer: 2000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          return
        }
        this.$socket.emit('accountcloud_login', {
          data: obj,
          type: 'password',
          callback_params: `${this.pagename}_${this.random_number}`,
        }, params => {
          try {
            if (params.type === 'success') {
              const role = params.data.level || 'visitor'
              let ability = []
              ability = accountAbility[params.data.level]
              const objuserinfo = {
                userpn: params.userpn,
                dealer: params.data.dealer,
                enable: params.data.enable,
                level: params.data.level,
                account: params.data.account,
                name: params.data.name,
                fullName: params.data.name,
                username: params.data.name,
                role,
                ability,
                datetime: params.datetime,
                expiredatetime: 0,
              }
              if (this.status) {
                objuserinfo.expiredatetime = new Date().getTime() + 60 * 60 * 1000 * 24 * 365 * 10
                this.$cookies.set('userData', encodeURIComponent(JSON.stringify(objuserinfo)), '100y')
                this.$cookies.set('userToken', params.token, '100y')
              } else {
                objuserinfo.expiredatetime = new Date().getTime() + 60 * 60 * 1000 * 24
                this.$cookies.set('userData', encodeURIComponent(JSON.stringify(objuserinfo)), '1h')
                this.$cookies.set('userToken', params.token)
              }
              localStorage.setItem('userData', JSON.stringify(objuserinfo))
              useJwt.setToken(params.token)
              useJwt.setRefreshToken(params.token)
              this.$ability.update(objuserinfo.ability)
              // refresh cookies
              this.$socket.disconnect()
              setTimeout(() => {
                this.$socket.connect()
              }, 500)
              this.$swal({
                icon: 'success',
                title: this.$t('common.welecomuserlogin', { account }),
                showConfirmButton: false,
                timer: 2500,
                buttonsStyling: false,
              }).then(result => {
                this.$router.push({ name: 'dashboard' }).catch(error => {
                  // console.warn(error.message)
                })
              })
            } else if (params.type === 'error') {
              let errormsg = ''
              if (params.msg === 'account_error_nouser') {
                errormsg = this.$t('error_common.account_error_nouser')
              } else if (params.msg === 'account_error_password') {
                errormsg = this.$t('error_common.account_error_userorpassword')
              } else if (params.msg === 'account_error_notenabled') {
                errormsg = this.$t('error_common.account_error_notenabled')
              } else if (params.msg === 'database_disconnect') {
                errormsg = this.$t('error_common.database_disconnect')
              } else {
                errormsg = params.msg
              }
              this.$swal({
                icon: 'error',
                title: errormsg,
                showConfirmButton: true,
                timer: 2000,
                buttonsStyling: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          } catch (error) {
            console.log(error)
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
